import placeholder from '../../assets/placeholder.svg'


const Team = () => {
    
    return ( 
        <div className="flex w-full justify-center ">
            <div className=" ml-0 flex max-w-screen-lg py-28 flex-col ">
           
                <div className="flex   justify-start items-start " style={{borderBottom: "2px solid rgb(212, 212, 212)"}}>
            
                <h1 className="text-black text-2xl font-bold mb-2 ">homest3ad-Team</h1>
                </div>
                <div>
                <p className='mt-2 text-lg font-semibold text-[#222222]/60'>Meet our team… we are curious, hard working, and experienced. </p>
                </div>
               
                <div className="flex xl:flex-row flex-col justify-center ">
                    <div className="xl:w-1/2 w-full">
                        <div className="card1 mt-10 xl:mr-2 xl:mx-0 mx-5 flex flex-col rounded-2xl bg-[#222222]">
                            <div className='flex p-5 flex-col h-[400px]'>
                            <div className=' flex xl:flex-row flex-col '>
                                <img className="w-1/4 " src={placeholder} alt="" />
                                <div className="flex justify-center flex-col xl:ml-2">
                                <h1 className='text-white text-2xl font-bold'>Annika Vrbsky</h1>
                                <p className='text-white/60 font-bold'>Co-founder</p>
                                </div>
                            </div>
                            <p className='text-white/60 mt-2  font-semibold'>Hey! I'm Annika, 20 years old and a former professional tennis player.
                            I love to face new challenges every day, learn from the people I meet and have a big passion for finance. That's why at homest3ad, I am responsible for all things related to customer relations, financials, and strategy.
                            Responsible for finance, sales, and strategy</p>
                            </div>
                            
                        </div>
                    </div>

                    <div className="xl:w-1/2 w-full ">
                        <div className="card1 mt-10 flex xl:mr-2 xl:mx-0 mx-5 flex-col rounded-2xl bg-[#222222]">
                            <div className='flex p-5 flex-col h-[400px]'>
                            <div className=' flex xl:flex-row flex-col '>
                                <img className="w-1/4" src={placeholder} alt="" />
                                <div className="flex justify-center flex-col xl:ml-2">
                                <h1 className='text-white text-2xl font-bold'>Jordi Mueller</h1>
                                <p className='text-white/60  font-bold'>Co-founder</p>
                                </div>
                            </div>
                            <p className='text-white/60 mt-2  font-semibold'>
                            Hey! I'm Jordi, 21 years old and a serial entrepreneur.
                            I’m passionate about solving problems by designing great products which are joyful and easy to use.
                            Responsible for development, design and strategy.</p>
                            </div>
                            
                        </div>
                    </div>

                    
                   
                </div>
            </div>
        </div>
    )
}

export default Team