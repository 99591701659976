import React, {useState} from "react"
import small_size from '../../assets/phone.svg'
import middle_size from '../../assets/Tablet.svg'
import large_size from '../../assets/Desktop.svg'



const Responsive = () => {
    
    const [mobile, setMobile] = useState(true)
    const [tablet, setTablet] = useState(false)
    const [desktop, setDesktop] = useState(false)
    
    return ( 
        <div className="flex flex-row xl:mt-32 sm:mt-32 items-center justify-center xl:px-0 px-5 ">
            <div className=" flex items-center max-w-screen-sm xl:max-w-screen-xl justify-center flex-col">
                <div className="flex mt-10 max-w-screen-xl flex-col">
                <div className="my-10 mt-32">
                 <button className="bg-black text-white font-bold text-sm rounded-lg p-2 mb-3">Repsoniveness</button>
                <h1 className="font-bold text-left  text-[#222222] text-4xl xl:text-6xl">Runs on all devices...<br/> <span className="text-[#222222]/60">All you need to do is scroll</span></h1>
                 </div>
                   
                    <div className="flex w-full flex-col">
                        <div className="flex flex-row xl:flex-row mb-5">
                           <button onClick={() =>setMobile(true)&setDesktop(false&setTablet(false))} 
                           style={{ backgroundColor: mobile ? '#97DEA3':"#ededed"}} className="bg-[#BFBCBC] hover:bg-[#BFBCBC] xl:mt-5 justify-center px-2 py-1 xl:py-10 xl:px-10  font-semibold text-[#222222] xl:text-2xl text-xl rounded-lg xl:rounded-2xl" >Phone</button>
                           <button onClick={() =>setTablet(true)&setDesktop(false)&setMobile(false)} style={{ backgroundColor: tablet ? '#97DEA3' : "#EDEDED" }} className="ml-2 xl:mt-5 px-2 py-1 justify-center xl:py-10 font-semibold text-[#222222] xl:text-2xl  text-xl xl:px-10 xl:rounded-2xl rounded-lg">Tablet</button>
                           <button onClick={() =>setDesktop(true)&setMobile(false)&setTablet(false)} style={{ backgroundColor: desktop ? '#97DEA3' : "#EDEDED" }} className="bg-[#EDEDED] xl:mt-5 ml-2 py-1 px-2 justify-center font-semibold text-[#222222] xl:text-2xl text-xl xl:py-10 xl:px-10 rounded-lg xl:rounded-2xl">Desktop</button>
                        </div>

                        {mobile ? 
                        <div className="flex-col rounded-2xl xl:flex-row mr-0 w-full flex  bg-[#ededed]/60">
                        {/* Phone div */}
                         
                            <div className="w-full flex">
                                <img src={small_size}  className=" py-3 w-full "></img>
                                
                            </div>
                        </div>
                        : null} 

                        {tablet ?            
                        <div className="flex-col rounded-2xl items-center xl:flex-row mr-0 w-full flex  bg-[#EDEDED]/60">
                        {/* Tablet div */}
                           
                            <div className="w-full flex items-center justify-center">
                                <img src={middle_size}  className=" py-3 3/4 "></img>
                                
                            </div>
                        </div>
                        : null} 
                      
                       
                        {desktop ?
                            <div className=" flex-col rounded-2xl xl:flex-row mr-0 w-full flex  bg-[#EDEDED]/60">
                        {/* Desktop div */}
                        
                            <div className="w-full flex items-center justify-center">
                            <img src={large_size}  className=" py-3 3/4 "></img>
                               
                            </div>
                            
                        </div>
                        : null} 
                        
                        
                    </div>
                        
                </div>
            </div>
        </div>
    )
}

export default Responsive 