import sample from '../../assets/sample.svg'
import jordi_image from '../../assets/jordi_image.png'
import annika_image from '../../assets/annika_image.png'


const AboutUs = () => {

    return ( 
            <div className="flex w-full  flex-col items-center justify-center  mt-32 xl:px-0 px-5">
            <div className='flex-col flex max-w-[1000px] '>
                <div className='justify-start items-start ml-0 mt-10'>
                    <h1 className='mb-1 text-2xl xl:text-4xl justify-start text-left items-start font-bold '>About us</h1>
                     <p className='mb-10 text-left text-lg font-medium text-[#222222]/60'>
                    This is where we spill the tea...
                     </p>
                    </div>
         <div className="flex flex-col"> 
            <div className='flex xl:flex-row flex-col items-start'>
                <div className='flex xl:w-1/2'>
                    <img src={annika_image} alt="" />
                </div>
                <div className='xl:w-1/4 xl:ml-12 mt-5 xl:mt-0 justify-center xl:items-center'>
                    <h1 className='font-bold text-2xl'>Annika</h1>
                    <p className='font-bold text-[#222222]/60'>Co-founder, CEO</p>
                    <br />
                    <p className='font-semibold text-[#222222]/60'>Hey! I'm Annika, 20 years old and a former professional tennis player. <br/> <br/>I love to face new challenges every day, learn from the people I meet and have a big passion for finance. <br/> <br /> That's why at homest3ad, I am responsible for all things related to customer relations, financials, and strategy.</p>
                </div>
            </div>
            <div className='flex flex-row items-center mt-36'>
                <div className='xl:w-1/4 xl:mr-12 justify-center items-center'>
                    <div className='flex xl:hidden'>
                         <img src={jordi_image} alt="" />
                     </div>
                    <h1 className='font-bold text-2xl mt-5 xl:mt-0'>Jordi</h1>
                    <p className='font-bold text-[#222222]/60'>Co-founder, CTO</p>
                    <br />
                    <p className='font-semibold text-[#222222]/60'>Hey! I'm Jordi, 21 years old and a serial entrepreneur.
                    I’m passionate about solving problems by designing great products which are joyful and easy to use.</p>
                </div>
                <div className='xl:flex hidden w-1/2'>
                    <img src={jordi_image} alt="" />
                </div>
            </div>
       </div>
       </div>
         </div>

        
    )
}

export default AboutUs