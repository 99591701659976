import React, { useEffect } from "react";
import homest3ad_logo from '../../assets/homest3ad_logo_white.png';
import { useHref } from "react-router-dom";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import background_image from '../../assets/background_image.png';
import snippet3 from '../../assets/image_snippet3.jpg';
import title_image from '../../assets/title_image.png'
import header_image from '../../assets/title_environment.png'
import { Popup_mission } from "../Pop-up/Popup";
import { PopupButton } from '@typeform/embed-react'
import Spline from '@splinetool/react-spline';

const Waitlist = () => {
    return (
      <PopupButton id="https://c4dngzrg1ey.typeform.com/to/OO2nNpYf" style={{ fontSize: 20 }} className="my-button">
        
        <button className="bg-[#F18A90] py-2 px-3 mt-5  text-[#222222] text-sm font-semibold  rounded-lg hover:bg-[#585858] cursor-pointer">Early Access!</button>
      </PopupButton>
    )
  }

const Header = () => {
    return (
      
        <div className='flex flex-col items-center bg-black '>   
         
                <div className="flex flex-col mt-28 mb-10 w-[100%] h-[600px] xl:h-[100%] items-center justify-center ">

                    <h1 className="font-bold  text-[#ffffff] text-[60px] text-center ">homest3ad</h1>
                    <p className="  font-semibold text-[#ffffff]/60 text-[20px] xl:text-2xl text-center ">Turn your brand into an <br/> experience </p> 
                    <Waitlist/>
                    <img className="xl:w-1/4 w-1/2 mt-10" src={header_image} alt="" />
                    </div>  
                    
                  
                  
                </div>
     
        
    )

}
export default Header