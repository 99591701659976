import { FaDove } from "react-icons/fa"
import card_1 from '../../assets/Card_1.svg'
import card_2 from '../../assets/Card_2.svg'
import card_3 from '../../assets/Card_3.svg'
import card_4 from '../../assets/Card_4.svg'
import card_5 from '../../assets/Card_5.svg'
import card_6 from '../../assets/Card_6.svg'


const DIY = () => {
    return ( 
        <div className="flex items-center justify-center xl:px-0 px-5">
            <div className="flex max-w-screen-xl flex-col">
            <div className='justify-start items-start ml-0 mt-10'>
            <div className="my-10 xl:mt-32 mt-12">
                 <button className="bg-black text-white font-bold text-sm rounded-lg p-2 mb-3">Do it yourself</button>
                <h1 className="font-bold text-left  text-[#222222] text-4xl xl:text-6xl">Yes, you can do all that by yourself... <br/> <span className="text-[#222222]/60">With our Tool.</span></h1>
              </div>
            </div>
            <div className="flex xl:flex-row flex-col">
                <div className="flex  flex-col sm:flex-row xl:flex-col">
                     <div className="flex flex-col xl:flex-row">
                        <img className="xl:mr-3 sm:mr-3 mb-3" src={card_1} alt="" />
                         <img className="xl:mr-3 sm:mr-3  mb-3" src={card_2} alt="" />
                    </div>
                    <div className="flex flex-col xl:flex-row">
                        <img className="xl:mr-3 sm:mr-3  mb-3" src={card_3} alt="" />
                        <img className="xl:mr-3 sm:mr-3  mb-3" src={card_4} alt="" />
                    </div>
                </div>
                <div className="flex sm:flex-row xl:flex-col flex-col  ">
                    <img className="xl:mr-3 sm:mr-3  mb-3" src={card_5} alt="" />
                    <img className="xl:mr-3 sm:mr-3  mb-3" src={card_6} alt="" />
                </div>
             </div>
         </div>
        </div>
    )
}

export default DIY