import placeholder2 from '../../assets/placeholder2.png'
import Ai_graphic from '../../assets/Ai_graphic.svg'
import logo_grahpic from '../../assets/logo_graphic.svg'
import tag_graphic from '../../assets/tag_graphic.svg'
import interface_graphic from '../../assets/interface_graphic.svg'
import world_graphic from '../../assets/world_graphic.png'


const Care = () => {
    return (
        <div className="flex w-full justify-center">
            <div className="flex flex-col max-w-screen-sm py-28">
            <div className="flex" style={{borderBottom: "2px solid rgb(212, 212, 212)"}}>
            
            <h1 className="text-black text-2xl font-bold mb-2 xl:ml-0 ml-5">Why we care and how we plan to do it</h1>
            </div>
                <div className="flex flex-row mt-10 xl:mx-0 mx-5">
                    <img className="w-1/2 rounded-xl" src={logo_grahpic} alt="" />
                    <div className='flex items-center'>
                        <h1 className='ml-4 text-[#222222]/60 font-bold xl:text-2xl text-xl'>
                        We started homest3ad because our team had a bunch of (great - we think) ideas, but no simple tool to present them uniquely and interactively on the internet.
                        </h1>
                    </div>
                </div>
                <div className='flex flex-row mt-10 xl:mx-0 mx-5'>
                    <div className='flex items-center'>
                        <h1 className='text-[#222222]/60  font-bold mr-4 xl:text-2xl text-xl  '>
                        Therefore, we have a personal passion in building a tool which understands ideas/businesses and automatically designs great communication channels around them.
                        </h1>
                    </div>
                    <img className="w-1/2 rounded-xl" src={tag_graphic} alt="" />
                </div>
                <div className="flex flex-row mt-10 xl:mx-0 mx-5">
                    <div className='flex items-center'>
                    <img className="w-1/2" src={interface_graphic} alt="" />
                        <h1 className='ml-4 text-[#222222]/60 font-bold xl:text-2xl text-xl'>
                        However creating another website builder won’t be enough... <br /> <br/>
                        We need to rethink the classic concept of a website entirely to improve how ideas are presented interactively.
                        </h1>
                    </div>
                </div>
                <div className='flex flex-row mt-10 xl:mx-0 mx-5'>
                    <div className='flex items-center'>
                        <h1 className='text-[#222222]/60  font-bold mr-4 xl:text-2xl text-xl '>
                        This is where 3D and  AI come into play. 3D allows anyone to present something with significantly more opportunities and gives ideas the room they so often need. 
                        </h1>
                    </div>
                    <img className="w-1/2" src={world_graphic} alt="" />
                </div>
                <div className="flex flex-row mt-10 xl:mx-0 mx-5">
                    <img className="w-1/2" src={Ai_graphic} alt="" />
                    <div className='flex items-center'>
                        <h1 className='ml-4 text-[#222222]/60 font-bold xl:text-2xl text-xl'>
                        AI allows us to not only automatize the creation and improvement of an environment but enables an interactive approach between machine and user during the design process.
                        </h1>
                    </div>
                </div>
                   

                

            </div>
        </div>
    )
}

export default Care 


