import { PopupButton } from '@typeform/embed-react'
import { motion } from "framer-motion";


const Waitlist = () => {
    return (
      <PopupButton id="https://c4dngzrg1ey.typeform.com/to/OO2nNpYf" style={{ fontSize: 20 }} className="my-button">
        
        <motion.div className="bg-[#222222] py-2 px-3 mt-5   text-[white] text-sm font-semibold  rounded-lg hover:bg-[#585858] cursor-pointer flex flex-row" whileHover={{ scale: 1.1}}
        whileTap={{
        scale: 0.6,
        borderRadius: "100%"}}>
          
          Join waitlist!
          </motion.div>
        
      </PopupButton>
    )
  }


const ShortPitch = () => {
    return (
        <div className='flex w-full flex-col bg-[#F9F9F9] mt-16 items-center xl:flex-col justify-center '>
           <div className="flex xl:max-w-screen-xl my-10  flex-col sm:flex-row xl:flex-row items-center justify-center"> 
                <img className=" w-3/4 xl:hidden sm:hidden" src="https://homest3adimagebucket.s3.eu-central-1.amazonaws.com/product_preview.svg" alt="" />
                <div className='mt-6 w-2/3 xl:w-full  flex flex-col items-center xl:items-start sm:items-start ml-0 sm:ml-5 '>
                    <h1 className="font-bold text-center xl:text-left sm:text-left text-[#222222]/100 text-xl xl:text-4xl sm:text-2xl xl:mr-10 ml-0 ">We provide you with the tool to turn your ideas into unique, interactive, emotional experiences and improve your performance. <br /> <br />
                    Create 3D environments without coding & integrate them on your website.</h1>
                    <Waitlist/>
               </div>
               <img className="xl:h-[700px] sm:h-[500px] xl:flex sm:flex hidden " src="https://homest3adimagebucket.s3.eu-central-1.amazonaws.com/product_preview.svg" alt="" />
        
          </div>
        </div>
    )
}

export default ShortPitch