import React from "react";
import { Application } from '@splinetool/runtime';


const Mainbody = () => {
    return (
       
        <div className=" w-full flex sm:items-center 
        justify-center md:items-center xl:items-center xl:px-20 bg-[#EFEFEF]">

            
            <div className="w-full flex flex-col items-center xl:mx-5 sm:mx-5 ">
                    <h1 className="font-bold text-center text-[#222222]/60 text-4xl xl:text-6xl py-20 xl:ml-10"> 
                         Client experiences 
                          <br/>
                        <span className="font-bold text-[#222222] text-4xl xl:text-6xl"> powered by homest3ad beta</span>
                    </h1>

                <div className="w-full flex justify-center items-center flex-col xl:flex-row lg:flex-row ">

                <div className=" xl:w-[400px]  rounded-2xl flex align-middle flex-col mt-5 xl:mr-3 xl:mt-0 bg-[#EFEFEF]/80">
                        <div className=" ">
                            <div className="rounded-lg xl:rounded-2xl h-[300px] w-full  xl:h-[400px] xl:w-[400px] lg:mx-2 lg:w-[350px] lg:h-[350px] flex items-start bg-[#2E2D2D] ">
                                
                            </div>
                            <div className="mt-5 m-5">
                                <p className='font-bold text-[#222222]/100 text-2xl'>READ-O</p>
                                <p className='font-medium text-[#222222]/60 text-1xl'> This environment is currently under development </p>
                                 <button className="button"
                                  onClick={() => {}}
                                 whileHover={{
                                 scale: 1.1,
                                    }}
                                >Under Development
                                </button>
                            </div> 
                       </div>
                  </div>
                
                
                       
                    
                  <div className=" xl:w-[400px]  rounded-2xl flex align-middle flex-col mt-5 xl:mr-3 xl:mt-0 bg-[#EFEFEF]/80">
                        <div className=" ">
                            <div className="rounded-lg xl:rounded-2xl h-[300px] w-full  xl:h-[400px] xl:w-[400px] lg:mx-2 lg:w-[350px] lg:h-[350px] flex items-start bg-[#2E2D2D] ">
                            </div>
                            <div className="mt-5 m-5">
                                <p className='font-bold text-[#222222]/100 text-2xl'>Stealth Mode</p>
                                <p className='font-medium text-[#222222]/60 text-1xl'> This environment is currently under development </p>
                                 <button className="button"
                                  onClick={() => {}}
                                 whileHover={{
                                 scale: 1.1,
                                    }}
                                >Under Development
                                </button>
                            </div> 
                       </div>
                  </div>
                  <div className=" xl:w-[400px]  rounded-2xl flex align-middle flex-col mt-5 xl:mr-3 xl:mt-0 bg-[#EFEFEF]/80">
                        <div className=" ">
                            <div className="rounded-lg xl:rounded-2xl h-[300px] w-full  xl:h-[400px] xl:w-[400px] lg:mx-2 lg:w-[350px] lg:h-[350px] flex items-start bg-[#2E2D2D] ">
                            </div>
                            <div className="mt-5 m-5">
                                <p className='font-bold text-[#222222]/100 text-2xl'>Stealth Mode</p>
                                <p className='font-medium text-[#222222]/60 text-1xl'> This environment is currently under development </p>
                                 <button className="button"
                                  onClick={() => {}}
                                 whileHover={{
                                 scale: 1.1,
                                    }}
                                >Under Development
                                </button>
                            </div> 
                       </div>
                  </div>
               </div>
            </div>
        </div>
    )
}

export default Mainbody 