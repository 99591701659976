import team from '../../assets/team.svg'
import care from '../../assets/care.svg'
import journey from '../../assets/journey.svg'
import investor from '../../assets/Investor.svg'
import { Link } from "react-router-dom";

const Storytime = () => {
    return (
    <div className="flex  flex-col items-center justify-center  mt-32 xl:mx-16 ">
        
        <div className='flex-col flex max-w-screen-xl xl:px-0 px-5 '>
        <div className="my-10 xl:mt-32">
                 <button className="bg-black text-white font-bold text-sm rounded-lg p-2 mb-3">Infos</button>
                <h1 className="font-bold text-left  text-[#222222] text-4xl xl:text-6xl">Wanna get more info about us?<br/> <span className="text-[#222222]/60">Click one of the boxes</span></h1>
        </div>
     <div className='flex flex-col xl:max-w-screen-xl '>
       <div className="flex xl:flex-row flex-col xl:mx-0">
           <div onClick={() => {window.location.href='/About Us'}}className=" cursor-pointer">
               <div className='absolute p-12 w-[350px]'>
                     <p className='text-white/60 font-bold'>Team</p>
                     <h1 className=' text-white text-xl font-bold'>
                     That's our team - great to meet you. Click to read more!
                    </h1>
                </div>
               <img className="object-cover" src={team} alt="" />
            </div>  
           <div onClick={() => {window.location.href='/Care '}} className=" cursor-pointer  xl:ml-10 mt-5 xl:mt-0">
           <div className='absolute p-12 w-[350px]'>
                     <p className='text-[#222222]/60 font-bold'>Care</p>
                     <h1 className=' text-[#222222] text-xl font-bold'>
                     Why we care about building homest3ad for you
                    </h1>
            </div>
            <img className="object-cover rounded-xl" src={care} alt="" />
           </div> 
       </div>
       <div className='flex xl:flex-row flex-col xl:mx-0'>
       <div onClick={() => {window.location.href='https://e17c12dea8d.typeform.com/to/ykGxeLZe'}} className=" cursor-pointer mr-10 mt-10">
           <div className='absolute z-0 p-12 w-[350px]'>
                     <p className='text-white/60 font-bold'>Journey</p>
                     <h1 className=' text-white font-bold text-xl'>
                    Join our waitlist to get early access!
                    </h1>
            </div>
            <img className="object-cover rounded-xl" src={journey} alt="" />
        </div>
       
       <div onClick={() => {window.location.href='https://tryhomestead.notion.site/homest3ad-Fundraising-Page-27a1ac99c3fb480db8f222b6ded55970 '}} className=" cursor-pointer mr-10 mt-10  ">
           <div className='absolute p-12 w-[350px]'>
                     <p className='text-[#222222]/60 font-bold'>Investor</p>
                     <h1 className=' text-[#222222] font-bold text-xl'>
                    Learn more about our business case
                    </h1>
            </div>
            <img className="object-cover rounded-xl" src={investor} alt="" />
           </div>
       </div>
       </div>    
       </div>
    </div>

    )
}

export default Storytime
