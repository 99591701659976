import React, {useRef} from "react"
import { FaAccessibleIcon } from "react-icons/fa"
import { motion } from "framer-motion";
import graphic_1 from '../../assets/graphic_1.svg'
import be_transform from '../../assets/be_first.mov'
const Transform = ()  => {
    
    const vid1 = useRef();
    
   const handlePlay = () => {
       vid1.current.play();
   }


    return (
 <div className="flex w-full items-center justify-center mt-20 bg-[#FFFFFF] xl:mx-0 px-5 "> 
     <div className="flex flex-col max-w-screen-xl items-left justify-start">
        <div className="my-10">
            <button className="bg-black text-white font-bold text-sm rounded-lg p-2 mb-3">Features</button>
             <h1 className="font-bold text-left  text-[#222222] text-4xl xl:text-6xl">Let's make something 3D. <br/> <span className="text-[#222222]/60">With one click.</span></h1>
        </div>
        <div className="flex bg-[#222222]/5 rounded-xl  flex-col items-center xl:flex-row justify-center  ">
            <div className="xl:w-[90%] p-5 bg-[#F4F4F4]  rounded-xl w-4/5">
                <video ref={vid1} className="" src="https://homest3adimagebucket.s3.eu-central-1.amazonaws.com/be_first.mov"/>
               
            </div>
            <div className="flex   flex-col mt-5 w-4/5 xl:ml-10">
                <div className="">
                    <h1 className="font-bold  text-[#222222] text-xl xl:text-2xl">We help brands tell stories by letting them combine emotional content like purpose statements or supply chains with their products.</h1>
                    <p className="font-bold  text-[#222222]/50 text-2xl xl:text-xl ">On the left you can see the mission statement of a beauty brand. It is mostly about sustainability and natural ingridients.</p>
                </div>
                <div>
                <motion.button className="bg-[#C3AEDB]/90 mt-6 py-2 px-3  text-[#222222] text-sm font-semibold  rounded-lg hover:bg-[#b49dce] cursor-pointer" 
                onClick={handlePlay}
                whileHover={{ scale: 1.02}}>      
                Turn this website part into 3D! 
                </motion.button>
                    <p className="font-light mt-1 text-sm text-[#222222]/90">Don’t be shy - press it!</p>
                </div>              
            </div>
        </div>

        
       

    
     </div>
        
 </div>
 )
}

export default Transform 