import Header from "./header/Header";
import Footer from "./footer/Footer";
import Calltoaction from "./calltoaction/Calltoaction";
import Product from "./product/Product";
import Features from './features/Features'
import Responsive from "./responsive/Responsive";
import ShortPitch from "./shortPitch/ShortPitch";
import Storytime from "./storytime/Storytime";
import Transform from "./transform/Transform";
import DIY from "./DIY/DIY"
import Purposestatement from "./purpose_statement/PurposeStatement";
import { AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import './home.css';



const Home = () => {
    return (       
        <div class="home">
            <Header/>
            <Purposestatement/>
            <ShortPitch/>
            <Transform/>
            <DIY/>
            <Responsive/>
            <Storytime/>
        </div>
    )
}

export default Home 