import React from "react";
import { Link } from "react-router-dom";
import {Popup_jordi, Popup_annika} from "../Pop-up/Popup";
import { useState } from "react";

const Footer = () => {
    return (
        <div className="flex flex-col justify-center">
            <div className="flex xl:flex-row flex-col justify-center">
                <div className="flex flex-col w-96 m-5 mt-20 xl:m-20">
                        <h3 className="font-bold text-[#222222]/60 text-xl">homest3ad</h3>
                        <p>Hi there! It's us, Annika & Jordi. We founded homest3ad and are backed by FS EC & YBG Hessen. Help us to build a great product and reach out! Looking forward hearing from you! </p>
                        <div className="flex flex-row">
                        <button className="bg-[#D4FACE] flex text-sm  hover:bg-[#F2F2F2] text-[black]/80 font-semibold mt-4 py-2 px-4 rounded-md" onClick={() => {window.location.href='/About Us'}}>About Us</button>
                    </div>
                </div>
                <div className="flex flex-row ">
                    <div className="flex w-96 flex-col m-5 xl:ml-10 mt-20">
                        <h4 className="mb-2 font-bold">About</h4>
                        <Link to ='privacy'>
                        <p>Privacy</p>
                        </Link>
                        <Link to = 'imprint'>
                         <p>Imprint</p>
                         </Link>
                        <Link to = 'contact'>
                        <p>Contact</p>
                        </Link>
                

                    </div>
                        <div className=" w-96 flex-col m-5 xl:ml-10 mt-20 ">
                        <h4 className="mb-2 font-bold">Social</h4>
                        <button className='w-30'onClick={() => { window.location.   href = 'https://www.linkedin.com/company/homest3ad/' } }>
                            Linked-In
                        </button>
                    </div>
                </div>
            </div>

        <div className="m-20 flex justify-center">
                <p>homest3ad ©2023 | all rights reserved</p>
            </div>
   
     </div>    
    )
}

export default Footer 