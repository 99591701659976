import React, { useState } from "react";
import { useRef } from "react";
import slider2 from '../../assets/Rectangle31.png';
import slider3 from '../../assets/Rectangle30.png';
import slider4 from '../../assets/Rectangle29.png';
import slider1 from '../../assets/Rectangle27.png';
import emptyRoom from '../../assets/emptyRoom.png';
import s1Content from '../../assets/slider1Content.png'
import s2Content from '../../assets/slider2Content.png'
import customModelsPreview from '../../assets/customModelsPreview.png'
import HoverVideoPlayer from "react-hover-video-player";
import customObjects from '../../assets/customObjectsRender.mp4'
import Mainbody from "../mainbody/Mainbody";







const Beta = () => {

    return (
        <div className='w-full flex flex-col items-center'>
             <div className="mx-6 py-20   xl:mt-20">
                <p className="font-bold text-[#262626]/100 text-6xl xl:text-8xl text-center ">Beta</p>  
                <p className="font-bold text-[#222222]/60 text-2xl text-center mb-5 max-w-lg mt-4"> Our invite only beta already powers some 3D experiences and helps us to collect further feedback and data to improve our initial homest3ad one version</p>
             </div>
             <iframe className="w-[1080px] h-[700px] rounded-2xl mb-4 hidden xl:flex" src="https://development.d1od4m4d8apnw4.amplifyapp.com/world_FE0EE506-3719-4DA1-AD1C-F147EABB083E" frameborder="0"></iframe>
            <Mainbody/>
            <div className=" w-full flex mx-10">
                 <div className="bg-[#D9D9D9]/30 flex w-full xl:my-10 justify-center xl:mx-20 items-center flex-row rounded-3xl">
                    <div className="my-10 mx-12 items-center justify-center flex xl:flex-row flex-row">
                         <img className="xl:mr-4 mr-2 w-1/4 h-1/4 rounded-2xl" src={slider1} alt="" />
                         <img className="xl:mr-4 mr-2 w-1/4 h-1/4 rounded-2xl"src={slider2} alt="" />
                        <img className="xl:mr-4 mr-2 w-1/4 h-1/4 rounded-2xl"src={slider3} alt="" />
                         <img className=" w-1/4 h-1/4  rounded-2xl"src={slider4} alt="" />
                    </div>
                 </div>
            </div>

            <div className="flex w-full bg-black flex-col justify-center xl:items-center">
            
                <div className="bg-white w-full items-center  flex xl:flex-row flex-col xl:justify-left justify-center xl:mx-10 ">
                    <div className="xl:w-1/2 w-3/4 flex relative xl:mr-3 justify-center bg-[#d9d9d9]/30 flex-col items-center text-center mx-10 rounded-3xl ">
                        <HoverVideoPlayer className="cursor-pointer"
                            videoSrc="https://homest3adimagebucket.s3.eu-central-1.amazonaws.com/tablet_demo0100-0431_2.mp4 " videoStyle={{borderRadius:20}} 
                            pausedOverlay={
                            <div className="w-full hidden xl:flex relative">
                                <div className="absolute  justify-center items-center top-14 mx-32">
                                     <h1 className="font-bold text-[#fff]/100 text-3xl">Basic Components</h1>
                                    <p className="font-semibold text-[#fff]/100 text-lg leading-6">Basic components already include an App, Stoy, How it works and more!</p>
                                </div>
                                 <img className="rounded-3xl "src="https://homest3adimagebucket.s3.eu-central-1.amazonaws.com/component_title_image.jpg" alt="" />
                            </div>
                       }
                            loadingOverlay="https://homest3adimagebucket.s3.eu-central-1.    amazonaws.com/object_preview2.jpg">
                        </HoverVideoPlayer>
                        <div className="xl:hidden w-full">
                            <h1 className="font-bold text-[#000]/60 text-3xl mt-3">Basic Components</h1>
                            <p className="font-bold text-[#000]/60 text-xl my-3 mx-3">Basic components already include an App, Stoy, How it works and more!</p>
                        </div>
                    </div>

                    <div className="xl:w-1/2 w-3/4 flex relative xl:ml-3 justify-center bg-[#d9d9d9]/30 flex-col items-center text-center xl:mt-0 mt-6 mx-10 rounded-3xl ">
                         <HoverVideoPlayer className="cursor-pointer"
                             videoSrc="https://homest3adimagebucket.s3.eu-central-1.amazonaws.com/customObjectsRender.mp4" videoStyle={{borderRadius:20}} 
                       
                       
                             pausedOverlay={
                                <div className="w-full hidden xl:flex relative">
                                     <div className="absolute  justify-center items-center top-14 mx-32">
                                    <h1 className="font-bold text-[#fff]/100 text-3xl">Basic  Objects</h1>
                                     <p className="font-semibold text-[#fff]/100 text-lg leading-6">All models added to the environments right now are fully custom made by the homest3ad team</p>
                                     </div>
                                    <img className="rounded-3xl "src="https://homest3adimagebucket.s3.eu-central-1.amazonaws.com/object_preview3.jpg" alt="" />
                            
                                </div>
                       }
                                loadingOverlay="https://homest3adimagebucket.s3.eu-central-1.amazonaws.com/object_preview2.jpg">
                        </HoverVideoPlayer>
                        <div className="xl:hidden w-full">
                            <h1 className="font-bold text-[#000]/60 text-3xl mt-3">Basic Objects</h1>
                            <p className="font-bold text-[#000]/60 text-xl my-3 mx-3">Basic components already include an App, Stoy, How it works and more!</p>
                        </div>
                    </div>
                    
                </div> 
           


            <div className="bg-white w-full items-center flex flex-col xl:flex-row justify-center xl:mx-10 "> 
                <div className="xl:w-1/2 w-3/4 flex relative xl:mr-3 mt-6 justify-center bg-[#d9d9d9]/30 flex-col items-center text-center mx-10 rounded-3xl ">
                    <HoverVideoPlayer className="cursor-pointer" 
                       videoSrc="https://homest3adimagebucket.s3.eu-central-1.amazonaws.com/empty_room_preview.mp4" videoStyle={{borderRadius:20}} 
                       pausedOverlay={
                        <div className="w-full hidden items-center xl:flex relative">
                            <div className="absolute justify-center items-center top-14 mx-32">
                                 <h1 className="font-bold text-[#fff]/100 text-3xl">Basic   Environments</h1>
                                <p className="font-semibold text-[#fff]/100 text-lg leading-6">In Beta we only offer one basic environment.</p>
                            </div>
                                 <img className="rounded-3xl "src="https://homest3adimagebucket.s3.eu-central-1.amazonaws.com/empty_room_image.jpg" alt="" />
                            
                        </div>
                       }
                       loadingOverlay="https://homest3adimagebucket.s3.eu-central-1.amazonaws.com/object_preview2.jpg">
                    </HoverVideoPlayer>
                    <div className="xl:hidden w-full">
                            <h1 className="font-bold text-[#000]/60 text-3xl mt-3">Basic Environments</h1>
                            <p className="font-bold text-[#000]/60 text-xl my-3 mx-3">In Beta we only offer one basic environment.</p>
                        </div>
                </div>



               


            </div>
        </div>
        <div className="w-full mt-20 flex items-center flex-col">
            <div className="mx-10">
                <h1 className="text-center font-bold text-[#222222]/60 text-6xl">General <span className="font-bold text-[#222222]/100 text-6xl">Information</span></h1>
            </div>
            <div className="flex mx-6  mt-20 xl:flex-row flex-col xl:h-96">
                <div className="flex w-72 rounded-2xl items-center bg-gradient-to-b from-[#A4E8E8]/30 to-[#4EB1B1]/30 xl:ml-5 flex-col mt-5 py-8">
                    <h1 className="font-bold text-[#222222]/100 xl:mt-20  text-6xl">3 sec.</h1>
                    <p className="font-bold text-[#222222]/60 mt-8 text-center mx-5 text-4xl">avg. loading time empty environment</p>
                </div>
                <div className="flex rounded-2xl py-8 mt-5 w-72 xl:ml-5 bg-gradient-to-b from-[#838DE9]/40 to-[#1926A0]/40 hover:cursor-default hover:from-[#838DE9]/40 hover:to-[#1926A0]/40  items-center flex-col">
                    <h1 className="font-bold text-[#222222]/100 xl:mt-20 text-6xl">8 sec.</h1>
                    <p className="font-bold text-[#222222]/60 mt-8 text-center mx-5 text-4xl">avg. loading time full environment</p>
                </div>
                <div className="flex py-8 w-72 mt-5 rounded-2xl bg-gradient-to-b from-[#97DEA3]/40 to-[#63E87A]/40 items-center xl:ml-5 flex-col">
                    <h1 className="font-bold text-[#222222]/100 xl:mt-20 text-6xl">Desktop</h1>
                    <p className="font-bold text-[#222222]/60 mt-8 text-center mx-5 text-4xl">only</p>
                </div>
                <div className="flex flex-col rounded-2xl bg-gradient-to-b from-[#FFC700]/40 to-[#F5CF45]/40 w-72 xl:ml-5 py-8 mt-5 items-center">
                     <h1 className="font-bold text-[#222222]/100 xl:mt-20 text-6xl">Custom</h1>
                    <p className="font-bold text-[#222222]/60 mt-8 text-center mx-5 text-4xl">built for each client</p>
                </div>
            </div>
        </div>
    </div>
        
    )
}
export default Beta