import React from "react";
import './popup.css';
import {FaBeer, FaBehance, FaLinkedin, FaRegBell} from 'react-icons/fa'
import close_popup from '../../assets/close_popup.svg';
import {motion} from 'framer-motion'


function Popup_mission (props) {
    return (props.trigger) ? (
        <div className="popup_mission">
            <div className ="bg-[#F2F2F2] absolute rounded-3xl h-4/6 w-4/5  xl:h-200 xl:w-1/2">
                <div className="flex flex-row justify-between items-center m-4">
                    <div className="flex justify-start">
                        
                    </div>
                    <div className="flex justify-end">
                        <button className="xl:w-10 xl:h-10 w-6 h-6 justify-end" onClick={() => props.setTrigger (false)}><img src={close_popup}></img></button>
                        {props.children}
                    </div>
                </div>

                <div className="m-4 ">
                <text className="font-bold text-[#222222]/60 text-xl xl:text-4xl">Product Vision
                    <br />
                    <br />
                     <span className="text-[#222222]">Enable anyone</span> to go from a <span className="text-[#222222]">pure idea </span>to <span className="text-[#222222]">presenting</span> it in <span className="text-[#222222]">interactive </span>and <span className="text-[#222222]">memorable ways</span> within just a <span className="text-[#222222]">couple of minutes...</span> 
                </text>
                <br />
                <br />
                     <div className="mt-3 flex ">
                        <FaLinkedin className="pointers" />
                    </div>
                </div>
            </div> 
        </div>  
     ) : "";  
}

function Popup_annika (props) {
    return (props.trigger) ? (
        <div className="popup_annika ">
            <div className ="bg-[#F2F2F2] rounded-3xl h-4/6 w-4/5  xl:h-200 xl:w-1/2">
                <div className="flex flex-row justify-between items-center m-4">
                    <div className="flex justify-start">
                        
                    </div>
                    <div className="flex justify-end">
                        <button className="xl:w-10 xl:h-10 w-6 h-6 justify-end" onClick={() => props.setTrigger (false)}><img src={close_popup}></img></button>
                        {props.children}
                    </div>
                </div>

                <div className="m-4 ">
                <text className="font-bold text-[#222222]/60 text-xl xl:text-4xl">Hey! I'm <span className="text-[#77C785]">Annika</span>, 20 years old and a <span className="text-[#77C785]">former professional tennis player</span>. 
                    <br />
                    <br />
                    I love to face new <span className="text-[#222222]">challenges</span> every day, <span className="text-[#222222]">learn </span> from the people I meet and have a big passion for <span className="text-[#222222]">finance.</span> That's why at homest3ad, I am responsible for all things related to <span className="text-[#222222]">customer relations, financials, and strategy.</span> 
                </text>
                <br />
                <br />
                <text className="font-semibold text-[#222222]/40 text-lg xl:text-4xl">Responsible for finance, sales, and strategy        
                </text>
                    <div className="xl:mt-3 mt-1 flex ">
                        <FaLinkedin className="pointers" onClick={() => {window.location.href='https://linkedin.com/in/jordi-muller-774648178'}}/>
                    </div>
                </div>
            </div> 
        </div>  
     ) : "";  
}

function Popup_jordi (props) {
    return (props.trigger) ? (
        <div className="popup_jordi ">
            <div className ="bg-[#F2F2F2] rounded-3xl h-4/6 w-4/5 xl:h-200 xl:w-1/2">
                <div className="flex flex-row justify-between items-center m-4">
                    <div className="flex justify-start">
                        
                    </div>
                    <div className="flex justify-end">
                        <button className="xl:w-10 xl:h-10 w-6 h-6" onClick={() => props.setTrigger (false)}><img src={close_popup}></img></button>
                        {props.children}
                    </div>
                </div>   

                <div className="m-4 ">
                <text className="font-bold text-[#222222]/60 text-xl xl:text-4xl">Hey! I'm <span className="text-[#92A7F0]">Jordi</span>, 21 years old and a <span className="text-[#92A7F0]">serial entrepreneur</span>. 
                    <br />
                    <br />
                    I’m  passionate about <span className="text-[#222222]">solving problems</span> by <span className="text-[#222222]">designing great products</span> which are <span className="text-[#222222]">joyful</span> and <span className="text-[#222222]">easy</span> to use. 
                </text>
                <br />
                <br />
                <text className="font-semibold text-[#222222]/40 text-xl xl:text-2xl">Responsible for development, design and strategy.        
                </text>
                    <div className="xl:mt-3 mt-1 flex ">
                        <FaLinkedin className="pointers" onClick={() => {window.location.href='https://linkedin.com/in/jordi-muller-774648178'}}/>
                    </div>
                </div>
            </div> 
        </div>  
     ) : "";  
}

export {Popup_mission, Popup_annika, Popup_jordi};