
const Reado = () => {

    return (
        <div className="flex items-center justify-center pt-36">
            
            <iframe className="w-[1300px] h-[650px] rounded-2xl" src="https://development.d1od4m4d8apnw4.amplifyapp.com/world_1C361C2F-6AA1-44BE-A8BA-82F18CA759DC" frameborder="0"></iframe>
        </div>
    )
}

export default Reado