import running_image from '../../assets/running_image.png'
import env_img from '../../assets/env_img.png'
import env2_img from '../../assets/env2_img.png'
import env3_img from '../../assets/env3_img.png'



const Purposestatement = () => {

    return (
        <div className="flex w-full justify-center">
            <div className="flex justify-center ">
                <div className='w-1/4 items-start xl:flex sm:flex hidden justify-start'>
                    <img className='mt-5' src={env3_img} alt="" />
                </div>
            <div className="flex xl:w-[600px] sm:w-[600px] w-[400px] justify-center items-center flex-col my-36">
                <div className="bg-black m-2 p-3  rounded-md">
                    <h1 className="text-white font-bold text-sm">Why we do this</h1>
                </div>
                <h1 className="text-center text-xl xl:text-4xl font-bold text-[#222222]/60 mt-3"><span className="text-[#222222]/100">We believe that our world is full of ideas. And certainly, our world is not 2D.</span> <br/> <br />
                 We want to support you - the creatives and visionaires of our time - and help you create unique experiences around your ideas. With the power of the third dimension.  
                 </h1>
            </div>
            <div className='w-1/4 items-start justify-start xl:flex sm:flex hidden flex-col'>
                    <img className="w-3/4 xl:ml-20 mt-5" src={env_img} alt="" />
                    <img className="w-3/4 xl:ml-20 mt-5" src={env2_img} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Purposestatement 