const Dsid = () => {
    return ( 
        <div className="flex items-center justify-center pt-36 flex-col">
            <h1>How it works</h1>
            <p>Explore here how it works</p>
           <iframe className="w-[1300px] h-[650px] rounded-2xl" src="https://development.d1od4m4d8apnw4.amplifyapp.com/world_BB153646-F6DF-4A0B-83AC-9EDA57E48381" frameborder="0"></iframe>
           
        </div>
    )
}

export default Dsid

