import React, { useRef,useState } from "react";
import Logo from '../../assets/homest3ad_logo.png'
import Calltoaction from "../calltoaction/Calltoaction"; 
import { Link } from "react-router-dom";
import { Popup_mission } from "../Pop-up/Popup";
import { PopupButton } from '@typeform/embed-react'

const Waitlist = () => {
  return (
    <PopupButton id="https://c4dngzrg1ey.typeform.com/to/OO2nNpYf" style={{ fontSize: 20 }} className="my-button">
      
      <button className="bg-[#F18A90] py-2 px-3  text-[#222222] text-sm font-semibold  rounded-lg hover:bg-[#585858] cursor-pointer">Early Access!</button>
    </PopupButton>
  )
}

const Navbar = () => {
    const [MissionPopup, setMissionPopup] = useState (false); 
    return (
      <header className="">
        <div className='w-full z-100 fixed flex  top-0 flex-row justify-between items-center py-2 xl:px-20 px-5 bg-[#222222]'>
        <div className = "w-[80px] md:w-[80px] ">
        <Link to ='/'>
            <img className="w-[40px] md:w-[40px] xl:w-[120px]"src={Logo} alt="homest3ad_logo"/>
        </Link>

            </div>
            <div className=" w-full flex flex-row xl:justify-start">
              <Link to ="/Story">
                <p className='text-sm text-[#ffffff] hover:text-[#ffffff]/60 mx-4 font-bold'>Story</p>
                </Link>
                <Link to ="/beta">
                <p className='text-sm text-[#ffffff] hover:text-[#ffffff]/60 mx-4 font-bold'>Beta</p>
                </Link>
                <Link to ="/About us">
                <p className='text-sm text-[#ffffff] hover:text-[#ffffff]/60 mx-4 font-bold'>About us</p>
                </Link>
                
                
                <Popup_mission trigger = {MissionPopup} setTrigger = {setMissionPopup}></Popup_mission>
               
            </div>
            <div className="w-full hidden  xl:flex justify-end">
                <Waitlist/>
            </div>
           
        </div>
        </header>
    )
}

export default Navbar 