import React from "react";
import './App.css';
import {Contact, Home, Privacy, Imprint, Beta, Investor, Team, Journey, Care, AboutUs, Reado, Dsid, Roover} from './components';
import { Routes, Route, Router, useParams } from 'react-router-dom'
import Footer from './components/footer/Footer';
import { AnimateSharedLayout, AnimatePresence } from "framer-motion";
import Navbar from './components/navbar/Navbar';
import Storytelling from "./components/Storytelling/Storytelling";





function App() {
  return (
    <div>  
    <Navbar/>
    <Routes>
      <Route path='/' element={[<Home/>]} />
      <Route path='/privacy' element={<Privacy/>} />
      <Route path='/contact' element={<Contact/>} /> 
      <Route path='/imprint' element={<Imprint/>}/>
      <Route path='/beta' element={<Beta/>}/>
      <Route path='/:id' element={[<Home/>]}/>
      <Route path='/investor' element={[<Investor/>]}/>
      <Route path='/team' element={[<Team/>]}/>
      <Route path='/journey' element={[<Journey/>]}/>
      <Route path='/care' element={[<Care/>]}/>
      <Route path='/About Us' element={[<AboutUs/>]}/>
      <Route path='/Story' element={[<Storytelling/>]}/>
      <Route path='/reado' element={[<Reado/>]}/>
      <Route path='/dsid' element={[<Dsid/>]}/>
      <Route path="/Roover" element={[<Roover/>]}/>
    </Routes>
    <Footer/>
    
  </div>
  )
}

export default App;
