import React from "react";
import './imprint.css';


const Imprint = () => {
    return (
        <div className="w-full pt-20 mx-10 xl:p-40">
            <button className='imprint_container_button'onClick={() => { window.location.href = '/' } }>
                Back
             </button>
            <div className="text-sm">
                <h1>Angaben gemäß § 5 TMG: </h1>
            </div>
            <div className='text-sm text-[#000000]'>
                <p>Annika Vrbsky UG haftungsbeschränkt </p>
                <p>Friedrich-Ebert-Str. 5a, 64297 Darmstadt</p>
                <br />
                <p>Telefonnummer: +49 162 5226823</p>
                <p>E-Mail: info@tryhomestead.io</p>
                <p>Website: www.homest3ad.com</p>
                <br />
                <p>Registergericht: Amtsgericht Darmstadt</p>
                <p>Registernummer: HRB 103617</p>
                <p>Vertretungsberechtigter Geschäftsführer: Annika Vrbsky</p>
                <br />
                <p>Registergericht: Amtsgericht Darmstadt</p>

            </div>
            
        </div>
    )
}

export default Imprint 