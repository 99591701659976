import React from 'react';
import './contact.css'; 


const Contact = () => {
    return (
    <div className='w-full mx-5 pt-40 xl:p-40'> 
        <button className='button'onClick={() => { window.location.href = '/' } }>
            Back
        </button>
        <h1 className='text-xl font-bold'>Contact</h1>
        <div className= 'text-sm'>
            <p> Annika Vrbsky UG haftungsbeschränkt</p>
            <p>Geschäftsführerin: Annika Vrbsky</p>
            <p>Adresse: Friedrich-Ebert-Str. 5a, 64297 Darmstadt, Deutschland </p>
            <p>Kontakt: info@tryhomestead.io</p>
        </div>
    </div>
    )
}

export default Contact 
