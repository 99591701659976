import team from '../../assets/team.svg'
import care from '../../assets/care.svg'
import journey from '../../assets/journey.svg'
import investor from '../../assets/Investor.svg'
import rollercoaster_creating_idea from '../../assets/rollercoaster_creating_idea.svg'

const Storytelling = () => {
    return ( 
        <div className="flex w-full  flex-col items-center justify-center  mt-32 ">
        
        <div className='flex-col flex max-w-screen-xl '>
        <div className='justify-start items-start ml-0 mt-10 xl:px-0 px-5'>
            <h1 className='mb-1  text-2xl xl:text-4xl  justify-start text-left items-start font-bold '>Storytime</h1>
            <p className='mb-10 text-lg font-medium text-[#222222]/60'>
                This is where we spill the tea...
            </p>
        </div> 
    <div className='flex xl:flex-row flex-col mb-10 xl:mx-0 mx-5'>
        <h1 className='p-3 pl-0 font-bold text-[#222222]/60'>Read more in our blog:</h1>
        <div onClick={() => {window.location.href='https://medium.com/@homest3ad/how-we-found-our-purpose-in-purpose-driven-brands-8c5eb61227e7'}} className=" cursor-pointer xl:ml-3 bg-[#ededed] justify-center rounded-lg font-semibold text-[#222222]/80 items-center p-3 xl:mt-0">How we found our purpose in purpose driven brands</div>
        <div onClick={() => {window.location.href='https://medium.com/@homest3ad/how-our-startups-team-met-5c18ca5ed0e1'}} className=" cursor-pointer xl:ml-3 bg-[#ededed] justify-center rounded-lg font-semibold text-[#222222]/80 items-center p-3 mt-3 xl:mt-0">How our Team met</div>
    </div>
     <div className='flex flex-col'>
       <div className="flex xl:flex-row flex-col mx-5 xl:mx-0">
           <div onClick={() => {window.location.href='/Care'}}className=" cursor-pointer">
               <div className=' absolute p-12 w-[350px]'>
                     <p className='text-[#222222]/60 font-bold'>Care</p>
                     <h1 className=' text-[#222222] text-xl font-bold'>
                     Why we care & How we plan to do it
                    </h1>
                </div>
               <img className="" src={care} alt="" />
            </div>  
           <div onClick={() => {window.location.href='https://medium.com/@homest3ad'}} className=" cursor-pointer xl:ml-10 mt-5 xl:mt-0">
           <div className='absolute p-12 w-[350px]'>
                     <p className='text-[#222222]/60 font-bold'>Idea</p>
                     <h1 className=' text-[#222222] text-xl font-bold'>
                    The rollercoaster of creating the idea
                    </h1>
            </div>
            <img className="rounded-xl" src={rollercoaster_creating_idea} alt="" />
           </div> 
       </div>
       </div>   
       </div>
    </div>

    )
}

export default Storytelling 